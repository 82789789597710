@charset "UTF-8";
@font-face {
  font-family: sofiaprolight;
  src: url("../font/sofiapro-light-webfont.woff2") format("woff2"), url("../font/sofiapro-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'web-educandojovenes21-icons';
  src: url("../font/web-educandojovenes21-icons.eot?26951821");
  src: url("../font/web-educandojovenes21-icons.eot?26951821#iefix") format("embedded-opentype"), url("../font/web-educandojovenes21-icons.woff2?26951821") format("woff2"), url("../font/web-educandojovenes21-icons.woff?26951821") format("woff"), url("../font/web-educandojovenes21-icons.ttf?26951821") format("truetype"), url("../font/web-educandojovenes21-icons.svg?26951821#web-educandojovenes21-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: century;
  src: url("../font/CenturyGothicRegular.woff2") format("woff2"), url("../font/CenturyGothicRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: century;
  src: url("../font/CenturyGothicBold.woff2") format("woff2"), url("../font/CenturyGothicBold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'web-educandojovenes21-icons';
    src: url('./font/web-educandojovenes21-icons.svg?26951821#web-educandojovenes21-icons') format('svg');
  }
}
*/
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: web-educandojovenes21-icons;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-plus:before {
  content: '\e802'; }

/* '' */
.icon-spin5:before {
  content: '\e838'; }

/* '' */
.icon-right:before {
  content: '\f006'; }

/* '' */
.icon-left:before {
  content: '\f007'; }

/* '' */
.icon-fb:before {
  content: '\f09a'; }

/* '' */
.icon-lin:before {
  content: '\f0e1'; }

/* '' */
/*
   Animation example, for spinners
*/
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block; }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #f9b412; }

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: rgba(249, 180, 18, 0.5); }

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #f9b412; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg); }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  font-family: century, sans-serif;
  font-size: 16px;
  min-height: 100vh;
  background: #47536a;
  user-select: none; }
  body * {
    box-sizing: border-box; }

ul {
  padding: 0;
  margin: 0 0 1.45rem 1.45rem;
  list-style-position: outside;
  list-style-image: none; }

p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%; } }

.carousel {
  min-height: 100vh;
  background-size: cover;
  background-position: right center;
  transition: all 0.3s;
  position: relative; }
  .carousel::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .carousel .content {
    position: absolute;
    width: 100%;
    margin-top: 35vh;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 1em;
    color: white;
    z-index: 20; }
    .carousel .content .text-container {
      text-align: left;
      margin: 0 25px; }
      .carousel .content .text-container.description {
        flex: 3 1;
        margin-right: 40vw; }
        .carousel .content .text-container.description h1 {
          margin-top: 4px;
          margin-bottom: 0.1em;
          line-height: 0.9em;
          font-size: 2em;
          font-weight: bold; }
        .carousel .content .text-container.description h4 {
          font-weight: normal;
          font-size: 1em;
          line-height: 1.25em;
          margin-bottom: 2.25em; }
        .carousel .content .text-container.description a {
          color: #f9b412;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 0.75em;
          border: 1px solid #f9b412;
          padding: 8px 32px;
          white-space: nowrap; }
      .carousel .content .text-container.item-count {
        flex: 0.5 1;
        text-align: right;
        min-width: 70px; }
        .carousel .content .text-container.item-count * {
          display: inline-block;
          vertical-align: top; }
        .carousel .content .text-container.item-count .current {
          font-size: 2em;
          font-family: century, sans-serif;
          color: #f9b412;
          padding-right: 4px; }
        .carousel .content .text-container.item-count .total {
          padding-top: 4px; }
    .carousel .content .arrow {
      flex-shrink: 1;
      font-size: 2em;
      cursor: pointer;
      transition: all 0.3s; }
      .carousel .content .arrow:hover {
        transform: translateY(-5px);
        transition: all 0.3s; }

.clients-carousel {
  transition: all 0.3s;
  margin: 80px 0; }
  .clients-carousel .content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    color: white; }
    .clients-carousel .content .arrow {
      flex-shrink: 1;
      font-size: 2em;
      cursor: pointer;
      transition: all 0.3s;
      z-index: 1;
      margin: 30px; }
      .clients-carousel .content .arrow:hover {
        transform: translateY(-5px);
        transition: all 0.3s; }
    .clients-carousel .content .clients-carousel-item {
      min-width: 600px;
      min-height: 420px;
      position: relative;
      color: #47536a; }
      .clients-carousel .content .clients-carousel-item::before {
        content: '';
        min-width: 600px;
        min-height: 420px;
        position: absolute;
        top: 10px;
        left: 10px;
        background: white;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.5); }
      .clients-carousel .content .clients-carousel-item .client-quote {
        background: white;
        min-width: 600px;
        min-height: 420px;
        position: absolute;
        display: flex;
        flex-flow: row nowrap;
        font-family: century, sans-serif;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.5); }
        .clients-carousel .content .clients-carousel-item .client-quote .client-quote-img {
          flex-basis: 50%;
          flex: 1 1;
          background-position: center;
          background-size: cover; }
        .clients-carousel .content .clients-carousel-item .client-quote .client-quote-text {
          flex-basis: 50%;
          flex: 1 1;
          display: flex;
          flex-flow: column nowrap; }
          .clients-carousel .content .clients-carousel-item .client-quote .client-quote-text .text {
            flex-basis: 70%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center; }
            .clients-carousel .content .clients-carousel-item .client-quote .client-quote-text .text blockquote {
              font-size: 1.5em;
              font-weight: bold;
              text-align: center; }
              .clients-carousel .content .clients-carousel-item .client-quote .client-quote-text .text blockquote::before {
                content: '“';
                color: #f9b412; }
              .clients-carousel .content .clients-carousel-item .client-quote .client-quote-text .text blockquote::after {
                content: '”';
                color: #f9b412; }
          .clients-carousel .content .clients-carousel-item .client-quote .client-quote-text .client p {
            font-family: century, sans-serif;
            text-align: center;
            margin-bottom: 0; }
            .clients-carousel .content .clients-carousel-item .client-quote .client-quote-text .client p:first-child::before {
              content: '-- ';
              color: #f9b412; }

.team-members {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center; }
  .team-members h1 {
    color: #f9b412; }
  .team-members .team-members-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center; }
    .team-members .team-members-list .member {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      margin: 40px; }
      .team-members .team-members-list .member .photo {
        min-width: 240px;
        min-height: 320px;
        background-position: center;
        background-size: cover;
        position: relative;
        margin-bottom: 20px; }
      .team-members .team-members-list .member .name {
        font-size: 1.2em; }
      .team-members .team-members-list .member .email {
        color: #f9b412;
        margin-bottom: 10px; }
    .team-members .team-members-list a {
      color: #f9b412;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 0.75em;
      border: 1px solid #f9b412;
      padding: 8px 32px;
      white-space: nowrap;
      max-width: 180px;
      text-align: center; }

.single-member {
  display: flex;
  flex-flow: row nowrap;
  padding: 40px 10%; }
  .single-member .member-details {
    min-width: 320px;
    z-index: 1; }
    .single-member .member-details h2 {
      color: #f9b412;
      padding-right: 20px; }
    .single-member .member-details .member-details-box {
      background: #384254;
      line-height: 2em;
      padding: 2em; }
      .single-member .member-details .member-details-box a {
        text-decoration: none;
        color: white; }
  .single-member .photo {
    flex: 0 0 240px;
    min-width: 240px;
    min-height: 320px;
    max-height: 320px;
    background-position: center;
    background-size: cover;
    position: relative;
    margin: 0 20px 0 -20px; }
  .single-member .team-member-pages .page-selector {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    margin: 5px;
    transition: background 100ms;
    cursor: pointer; }
    .single-member .team-member-pages .page-selector.active {
      background: #f9b412;
      transition: background 100ms; }
  .single-member .team-member-pages .page-title {
    color: #f9b412;
    line-height: 4em; }

.contact-page {
  display: flex;
  flex-flow: row wrap; }
  .contact-page .info {
    flex: 1 1 35%;
    padding: 160px 60px 60px;
    text-align: right;
    color: white; }
    .contact-page .info h1 {
      color: #f9b412;
      font-size: 2.5em; }
  .contact-page .form {
    flex: 1 1 65%;
    padding: 160px 60px 60px; }
    .contact-page .form form {
      display: flex;
      flex-flow: column nowrap;
      max-width: 480px;
      min-width: 360px;
      margin-left: auto;
      margin-right: auto; }
      .contact-page .form form input,
      .contact-page .form form textarea {
        margin: 10px;
        background-color: transparent;
        border: none;
        font-size: 1.25em;
        font-family: century, sans-serif;
        color: white;
        border-bottom: 1px solid white; }
        .contact-page .form form input::placeholder,
        .contact-page .form form textarea::placeholder {
          color: rgba(255, 255, 255, 0.5); }
      .contact-page .form form .form-bottom {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px; }
        .contact-page .form form .form-bottom input[type='submit'] {
          margin: 10px;
          cursor: pointer;
          color: #f9b412;
          border: 1px solid #f9b412;
          padding: 8px 30px; }
        .contact-page .form form .form-bottom label {
          color: #f9b412;
          text-transform: uppercase; }
      .contact-page .form form .thanksMessage {
        color: white;
        margin: 10px;
        opacity: 0; }
        .contact-page .form form .thanksMessage.visible {
          opacity: 1;
          transition: opacity 1s; }
      .contact-page .form form p {
        margin: 0.25em 10px !important;
        font-size: 0.75em !important; }
  .contact-page .map-container {
    flex: 1 1 100%; }
    .contact-page .map-container iframe {
      width: 100%;
      height: 420px; }

.index-text-section {
  flex: 0 0 45%;
  align-self: center;
  padding-left: 20%; }
  .index-text-section.large {
    flex-basis: 100%; }
  .index-text-section.services-list {
    display: flex;
    flex-flow: row wrap; }
    .index-text-section.services-list h1 {
      flex-basis: 100%;
      padding-left: 20px; }
  .index-text-section.clients-list {
    display: flex;
    flex-flow: row wrap; }
    .index-text-section.clients-list h1 {
      flex-basis: 100%;
      padding-left: 20px; }
    .index-text-section.clients-list .client-logos {
      margin-left: 20px;
      margin-right: 0;
      border: 1px solid #f9b412;
      border-right: none;
      border-left-width: 20px; }
  .index-text-section a.btn {
    color: #f9b412;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.75em;
    border: 1px solid #f9b412;
    padding: 8px 32px;
    white-space: nowrap; }

.article {
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: row nowrap;
  color: white; }
  .article img[alt='logo'] {
    height: 38px;
    margin-bottom: 0;
    float: left; }

.column {
  /* 100: header and 60: footer */
  padding: 100px 20px 60px; }
  .column.blue {
    background: #47536a; }
  .column.alpha-blue {
    background: rgba(71, 83, 106, 0.8); }
  .column.red {
    background: #f9b412; }
  .column.auto {
    flex: 1 1; }
  .column.big {
    flex: 0 0 45%; }
  .column.medium {
    flex: 0 0 30%; }

.service-title-box {
  background: rgba(56, 66, 84, 0.5);
  width: 320px;
  padding: 40px 20px 60px 60px;
  margin: 20px;
  position: relative;
  text-decoration: none; }
  .service-title-box .number {
    position: absolute;
    left: 0;
    top: -0.5em;
    color: #f9b412;
    font-size: 3em;
    font-weight: bold; }
  .service-title-box .title {
    color: white;
    font-size: 1.2em; }
    .service-title-box .title::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      background: #ff615a;
      padding: 1px 30px;
      top: 25px;
      left: 60px; }
  .service-title-box .corner {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: #f9b412;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; }
  .service-title-box.no-number {
    padding-left: 20px; }
    .service-title-box.no-number .title::before {
      left: 20px; }

.client-logos {
  display: flex;
  flex-flow: row wrap;
  margin: 0 15%;
  justify-content: center; }
  .client-logos h1 {
    flex: 1 1 100%;
    color: #f9b412;
    margin-left: 20px; }
  .client-logos .logo {
    width: 150px;
    height: 100px;
    margin: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }

.news-list {
  display: flex;
  flex-flow: row wrap;
  margin: 0 10%;
  justify-content: space-between; }
  .news-list h1 {
    flex: 1 1 100%;
    color: #f9b412;
    margin-left: 20px; }
  .news-list .service-title-box {
    flex: 1 1 320px; }

.more-content-arrow {
  border: 1px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 40px;
  transform: translateX(50vw);
  margin-left: -40px;
  opacity: 1;
  transition: opacity 500ms;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center; }
  .more-content-arrow.hidden {
    opacity: 0;
    transition: opacity 500ms; }
  .more-content-arrow i {
    transform: rotateZ(90deg); }

.columnContent {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 100px 50px 50px 20px;
  min-height: 70vh; }
  .columnContent h1,
  .columnContent h2 {
    text-align: left;
    font-weight: normal;
    font-size: 2em; }
  .columnContent h1 {
    color: #f9b412;
    font-weight: bold; }
  .columnContent h2 {
    font-size: 2em; }
  .columnContent h3 {
    color: #f9b412;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.25em;
    margin-top: 2em;
    margin-bottom: 0.25em; }
  .columnContent p {
    font-size: 1em;
    line-height: 1.25em;
    margin-bottom: 1em;
    text-align: justify; }
  .columnContent ul li {
    line-height: 1.25em; }
  .columnContent a {
    color: #f9b412;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.75em;
    border: 1px solid #f9b412;
    padding: 8px 32px;
    white-space: nowrap; }
  .columnContent .layout-one-image {
    min-height: 480px;
    background-position: center;
    background-size: cover;
    min-width: 240px;
    margin-left: -60px;
    position: relative; }
  .columnContent .layoutTwoImage {
    min-height: 360px;
    background-position: center;
    background-size: cover;
    min-width: 240px;
    margin-left: -60px;
    position: relative; }
    .columnContent .layoutTwoImage::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(249, 180, 18, 0.4); }
  .columnContent.layout-2, .columnContent.layout-4 {
    justify-content: center; }
    .columnContent.layout-2 h1,
    .columnContent.layout-2 h2, .columnContent.layout-4 h1,
    .columnContent.layout-4 h2 {
      position: relative;
      font-weight: normal;
      margin-top: 0;
      text-align: left; }
    .columnContent.layout-2 h1, .columnContent.layout-4 h1 {
      padding-bottom: 0.25em; }
    .columnContent.layout-2 h2, .columnContent.layout-4 h2 {
      color: #f9b412;
      padding-bottom: 0;
      margin-bottom: 0;
      font-size: 1em; }
    .columnContent.layout-2 h1::after, .columnContent.layout-4 h1::after {
      position: absolute;
      content: '';
      width: 40%;
      bottom: 0;
      right: 0;
      border-bottom: 1px solid white; }
    .columnContent.layout-2 .subtitle, .columnContent.layout-4 .subtitle {
      color: #f9b412; }
  .columnContent.layout-2 {
    justify-content: flex-start;
    padding-top: 150px;
    padding-left: 10%; }
  .columnContent.layout-3 {
    min-height: 100vh; }
    .columnContent.layout-3 h2 {
      margin-bottom: 0; }
    .columnContent.layout-3 .subtitle h3 {
      color: white;
      margin-top: 0; }
  .columnContent.layout-4 h1 {
    margin-right: 50%;
    padding-left: 0; }
  .columnContent.layout-4 ul li {
    line-height: 1.25em;
    margin-bottom: 0; }
  .columnContent.layout-5 {
    padding-top: 50px;
    padding-right: 20px;
    min-height: 75vh; }
  .columnContent.layout-6 {
    padding-top: 0px;
    padding-left: 0px;
    min-height: 75vh; }
    .columnContent.layout-6 h1,
    .columnContent.layout-6 h2 {
      font-size: 1.5em;
      line-height: 1em;
      margin: 0; }

.header {
  background: linear-gradient(180deg, #47536a 75%, transparent);
  margin-bottom: 1.45rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  padding: 0 50px; }
  .header.main-page {
    background: transparent; }
  .header .logo {
    margin: 0;
    background: url(../images/logo-sm.png) center no-repeat;
    background-position: left;
    height: 100px;
    width: 100px; }
  .header .menu-button {
    display: none; }
  .header ul {
    display: flex;
    flex-flow: row;
    align-items: center;
    list-style: none;
    margin: 0;
    position: relative;
    font-family: century, sans-serif; }
    .header ul li {
      padding: 10px;
      text-transform: uppercase; }
      .header ul li:hover > .down-arrow {
        display: block; }
      .header ul li:hover > ul {
        display: block;
        background: rgba(10, 22, 53, 0.6); }
      .header ul li a {
        color: white;
        text-decoration: none;
        letter-spacing: 0.3em;
        font-size: 0.75em; }
        .header ul li a::after {
          content: '';
          position: absolute;
          bottom: 4px;
          /* Align with padding */
          right: 10px;
          width: 0;
          height: 2px;
          background-color: #f9b412;
          transition: width 0.3s; }
        .header ul li a.active {
          color: #f9b412;
          transition: color 0.3s; }
      .header ul li div.down-arrow {
        position: relative;
        z-index: 1;
        display: none; }
        .header ul li div.down-arrow::after {
          content: '';
          position: absolute;
          bottom: -20px;
          left: 50%;
          margin-left: -10px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid white; }
      .header ul li ul {
        display: none;
        position: absolute;
        top: 105%;
        left: 0;
        width: 100%;
        padding: 20px;
        border-top: 2px solid white;
        margin-top: -2px; }
        .header ul li ul li {
          margin-bottom: 0;
          min-width: 200px;
          padding: 3px 10px;
          line-height: 1.5em; }
          .header ul li ul li:hover::after {
            content: none; }
          .header ul li ul li a {
            font-size: 0.75em;
            letter-spacing: initial; }
            .header ul li ul li a:after {
              content: none; }
          .header ul li ul li:hover {
            background-color: white;
            transition: all 0.3s; }
            .header ul li ul li:hover > a {
              color: #f9b412; }

.mainContainer {
  min-height: 100vh; }

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 0 50px 10px; }
  .footer .footer-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid transparent;
    border-right: none; }
    .footer .footer-container.with-border {
      float: right;
      padding-right: 50px;
      margin-right: -50px;
      border: 1px solid #f9b412;
      border-right: none;
      background: #47536a; }
    .footer .footer-container .link-to-contact-container {
      color: white;
      margin: 0 25px; }
      .footer .footer-container .link-to-contact-container span {
        font-size: 1.2em; }
      .footer .footer-container .link-to-contact-container a {
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.75em;
        border: 1px solid white;
        padding: 8px 32px;
        white-space: nowrap; }
  .footer ul {
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    margin: 0;
    margin-left: 25px; }
    .footer ul li {
      padding-top: 0px;
      margin: 10px 5px;
      width: 24px;
      height: 24px;
      border: solid 1px white;
      background: white;
      border-radius: 50%;
      transition: all 0.3s; }
      .footer ul li:hover {
        transform: translateY(-5px);
        transition: all 0.3s;
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.6); }
      .footer ul li a {
        text-decoration: none;
        color: #47536a;
        font-size: 1em; }
        .footer ul li a .icon-fb {
          margin-left: -1px; }

.loading {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 2em; }
  .loading a {
    color: #f9b412;
    text-decoration: none; }

.scrollable {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgba(71, 83, 106, 0.8); }
  .scrollable.blog {
    min-height: 350px;
    max-height: 60vh;
    padding: 25px 50px; }
  .scrollable.news {
    min-height: 400px;
    max-height: 70vh;
    padding: 20px; }
  .scrollable a {
    text-decoration: none;
    color: #f9b412; }

.blogPost h1 {
  color: #f9b412;
  border-bottom: 1px solid white;
  font-size: 2em;
  text-align: left;
  margin-top: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.blogPost h4 {
  color: #f9b412;
  margin-bottom: 0;
  text-transform: uppercase; }

.blogPost p {
  text-align: justify;
  font-size: 0.75em;
  line-height: 1.2em; }

.newsItem h1 {
  font-size: 2em;
  text-align: left;
  padding: 0;
  text-transform: uppercase;
  margin-bottom: 0; }

.newsItem h4,
.newsItem h2 {
  color: #f9b412; }

.newsItem p {
  margin-top: 1em;
  line-height: 1.2em;
  font-size: 0.75em; }

.videowrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }

.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.menu-button {
  width: 32px;
  height: 32px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }

.menu-button span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

.menu-button span:nth-child(1) {
  top: 0; }

.menu-button span:nth-child(2) {
  top: 14px; }

.menu-button span:nth-child(3) {
  top: 28px; }

ul.visible .menu-button span:nth-child(1) {
  top: 14px;
  transform: rotate(135deg); }

ul.visible .menu-button span:nth-child(2) {
  opacity: 0;
  left: -60px;
  width: 0; }

ul.visible .menu-button span:nth-child(3) {
  top: 14px;
  transform: rotate(-135deg); }

@media (max-width: 767px) {
  #root {
    max-width: 100vw;
    overflow: hidden; }
  .header {
    padding: 0;
    z-index: 100; }
    .header a .logo {
      background-position: 20px; }
    .header ul {
      padding-right: 15px;
      position: absolute;
      width: 100%;
      height: 100vh;
      background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.75), black);
      flex-flow: column nowrap;
      align-items: flex-end;
      top: 0;
      right: -100%;
      transition: all 0.3s; }
      .header ul .menu-button {
        display: block;
        position: absolute;
        background: transparent;
        top: 25px;
        right: calc(100% + 25px);
        opacity: 1;
        transition: all 0.3s;
        border: none; }
        .header ul .menu-button:focus {
          outline: none;
          border: none; }
      .header ul.visible {
        width: 100%;
        right: 0;
        transition: all 0.3s; }
        .header ul.visible .menu-button {
          right: 25px;
          transition: all 0.3s; }
      .header ul li {
        text-align: right; }
        .header ul li:nth-child(2) {
          margin-top: 80px; }
  .header ul li:hover > ul {
    height: auto; }
  .header ul li ul {
    position: static;
    padding: 0; }
    .header ul li ul li {
      margin-top: 0 !important;
      padding-left: 0;
      padding-right: 0; }
      .header ul li ul li a {
        padding-right: 10px; }
  .header ul li ul li:hover > ul {
    display: block; }
  .header ul li ul li ul {
    display: none;
    position: static;
    top: -1px;
    left: 100%; }
  .article {
    flex-flow: column nowrap; }
    .article .column {
      padding-top: 0; }
      .article .column .columnContent.layout-2 {
        min-height: auto;
        padding-top: 0; }
        .article .column .columnContent.layout-2 .service-title-box {
          width: 260px; }
    .article .column:first-child {
      padding-top: 150px; }
  .column {
    padding-top: 100px;
    padding-bottom: 40px; }
  .columnContent {
    padding: 10px; }
    .columnContent h1 {
      font-size: 1.5em; }
  .loading {
    font-size: 1.5em;
    text-align: center; }
  form {
    min-width: 260px; }
  .carousel .content .text-container.item-count {
    display: none; }
  .carousel .content .text-container.description {
    margin-right: 4vw; }
  .footer-container.with-border {
    margin-bottom: -10px; }
    .footer-container.with-border .link-to-contact-container.text {
      display: none; }
  .more-content-arrow {
    display: none; }
  .single-member {
    flex-flow: column;
    align-items: center; }
    .single-member .member-details {
      width: auto; }
      .single-member .member-details .member-details-box {
        padding: 1em; }
    .single-member .photo {
      margin: 0; }
  .contact-page .info {
    padding-top: 120px; }
  .contact-page .form {
    padding: 0; }
  .contact-page .map-container {
    padding: 10px; } }
